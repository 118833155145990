.circle {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 10px;
  float: left;
}

.circle-container {
  height: 50px;
  padding-bottom: 60px;
  margin-left: 10px;
}

.traffic-light-text {
  padding-top: 8px;
}
