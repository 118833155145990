body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

#root {
  padding: 0;
  margin: 0;
}

.form-control {
  margin-bottom: 10px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.small {
  font-size: 12pt;
}

article {
  padding: 10px;
}

footer {
  padding: 16px;
}

.card {
  margin-bottom: 10px;
}

header {
  width: 100%;
  height: 148px;
  position: fixed;
  background-color: rgb(0, 175, 0);
  border-bottom: 4px solid rgb(255, 0, 102);
  z-index: 999;
}

header > h1,
header a,
header a:hover,
header p {
  color: white;
  text-decoration: none;
  margin: 8px;
}

#headerFixer {
  height: 148px;
}

#menuRightPart {
  position: absolute;
  top: 20px;
  right: 32px;
}

.pointer {
  cursor: pointer !important;
}

.left {
  float: left;
}

.editorFormControll {
  width: 100%;
  background: transparent;
  border: none;
}

.orderFormControll {
  width: 100%;
  background: transparent;
  border: none;
  text-align: right;
  width: 64px;
  outline: none;
}

.pharCard > .card-header {
  background-color: rgb(0, 175, 0);
  color: #ffffff;
}

.pharCard {
  border-color: rgb(0, 175, 0);
  margin: 32px;
}

input[type="number"].orderFormControll::-webkit-inner-spin-button,
input[type="number"].orderFormControll::-webkit-outer-spin-button {
  opacity: 1;
  margin-left: 10px;
}

.center {
  text-align: center;
}

.actPrice:not(.defaultPrice),
.actPriceContainer {
  background-color: rgb(157, 189, 111);
}

.totalPriceDiv {
  margin-right: 32px;
}

.indexOffset {
  margin-right: 32px;
}

.buttonOffset {
  margin-right: 10px;
}

#giftsContainer {
  padding-left: 32px;
}

.hidden {
  display: none;
}

#deliverMap {
  border: none;
  width: 100%;
  height: 50vh;
}

#products td,
#products th {
  padding: 5px 10px;
}

#recapitulation {
  padding: 32px;
}

#giftsCont ul {
  list-style-type: none;
}

input:read-only {
  background-color: white !important;
}

.text-right {
  text-align: right;
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.no-arrows[type="number"] {
  -moz-appearance: textfield;
}

.btn-group-toggle > .btn-primary:not(.actice) {
  background-color: grey;
}

.addContainer {
  margin: 32px 0;
  width: 100%;
  text-align: center;
}

.addContainer > img {
  width: 80%;
}

.editorIsHighlighted {
  outline: 2px solid rgb(255, 0, 102);
  box-shadow: none;
}

.table-highlighted {
  background-color: rgba(255, 167, 202, 0.932);
}

.giftCol {
  padding-top: 8px;
  padding-left: 32px;
}
