.calendarItem {
  height: 70px;
  width: 70px;
  background-color: #dc3545;
  border-radius: 16px;
  margin: 2px;
  cursor: pointer;
  text-align: center;
  line-height: 11px;
}

.calendarItem.selected {
  background-color: #28a745;
}

.calendarItem.today {
  background-color: #ffc107;
}

.calendarItem.today.selected {
  width: 74px;
  height: 74px;
  border: 4px solid #28a745;
}

.calendarItem > span {
  color: white;
  font-family: sans-serif, "Helvetica Neue", "Lucida Grande", Arial;
  font-stretch: extra-condensed;
  font-size: 11px;
}

#calendar td {
  vertical-align: inherit;
  text-align: center;
  width: 78px;
  height: 78px;
  padding: 2px;
  border: 1px solid #dee2e6;
}
